// seso content social
if (typeof a1ContentSocial === 'undefined') {
    const SesoContentSocial = class {
        constructor(selector) {
            this.el = {};
            this.el.wrapper = selector instanceof HTMLElement
                ? selector
                : (
                    document.getElementById(selector)
                    || document.querySelector(selector)
                );

            this.el.cookieHint = this.el.wrapper.querySelector('.js-a1-content-social-cookie-hint')
                ? this.el.wrapper.querySelector('.js-a1-content-social-cookie-hint')
                : undefined;

            this.el.socialEmbed = this.el.wrapper.querySelector('.js-a1-content-social-embed')
                ? this.el.wrapper.querySelector('.js-a1-content-social-embed')
                : undefined;

            this.params = {
                classHidden: 'is-hidden',
                cookieSocialName: 'OptanonConsent',
                cookieAlertBoxClosedName: 'OptanonAlertBoxClosed',
                cookieAlertBoxClosedValue: '',
                socialCookieValue: '',
                socialCookieGroups: 'groups',
                socialCookiePart: '%2C8%3A' // group 8 for social media cookies
            };

            this.initContentSocial();
        }

        initContentSocial() {
            // if edit mode -> show embed, dont show cookie hint
            if (!document.body.classList.contains('has-edit-mode-menu')) {
                // if not edit mode
                // check cookies
                window.setInterval(this.checkCookie.bind(this), 250);
            } else {
                // is edit mode -> show social content
                this.showSocialContent();
            }
        }

        checkCookie() {
            let socialCookieValue = '0';

            const lastAlertBoxClosedCookie = this.params.cookieAlertBoxClosedValue;
            const currentAlertBoxClosedCookie = String(document.cookie.match('(^|;) ?' + this.params.cookieAlertBoxClosedName + '=([^;]*)(;|$)'));

            const lastSocialCookie = this.params.socialCookieValue;
            const currentSocialCookie = String(document.cookie.match('(^|;) ?' + this.params.cookieSocialName + '=([^;]*)(;|$)'));

            if ((currentAlertBoxClosedCookie !== null && currentAlertBoxClosedCookie !== lastAlertBoxClosedCookie)
                || (currentSocialCookie !== 'null' && currentSocialCookie !== lastSocialCookie)) {
                this.params.cookieAlertBoxClosedValue = currentAlertBoxClosedCookie;
                this.params.socialCookieValue = currentSocialCookie;

                // check social cookie
                if (currentSocialCookie !== 'null') {
                    const socialCookieGroups = currentSocialCookie.split('&');

                    if (socialCookieGroups.length > 1) {
                        socialCookieValue = this.getSocialCookieValue(socialCookieGroups);
                    }
                }
                // manage social content view if settings have changed
                this.manageSocialContentView(socialCookieValue);
            }
        }

        getSocialCookieValue(socialCookieGroups) {
            let i = 0;
            let socialCookieValue = '0';

            for (i = 0; i < socialCookieGroups.length; i++) {
                if (socialCookieGroups[i].indexOf(this.params.socialCookieGroups) >= 0) {
                    const socialCookieParts = socialCookieGroups[i].split(this.params.socialCookiePart);
                    socialCookieValue = socialCookieParts[1].substring(0, 1);
                    break;
                }
            }

            return socialCookieValue;
        }

        manageSocialContentView(socialCookieValue) {
            if (typeof socialCookieValue !== 'undefined' && socialCookieValue == '1') {
                // if cookies for social media are allowed
                this.showSocialContent();
            } else {
                // if social media cookies are blocked
                this.hideSocialContent();
            }
        }

        hideSocialContent() {
            this.el.cookieHint.classList.remove(this.params.classHidden);
            this.el.socialEmbed.classList.add(this.params.classHidden);
        }

        showSocialContent() {
            this.el.cookieHint.classList.add(this.params.classHidden);
            this.el.socialEmbed.classList.remove(this.params.classHidden);
        }
    };

    if (typeof window !== 'undefined') {
        window.a1ContentSocial = SesoContentSocial;
    }

    window.addEventListener('load', () => {
        // content-social
        document.querySelectorAll('a1-fragment[name="content-social"]').forEach(function(fragment) {
            // params: selector
            window.a1ContentSocial = new SesoContentSocial(fragment.querySelector('.a1-content-social'));
        });
    });
}
