if (typeof SesoCopy === 'undefined') {
    const SesoCopy = class {
        constructor(selector) {
            this.el = {};
            this.el.container = selector instanceof HTMLElement
                ? selector
                : (
                    document.getElementById(selector) ||
                    document.querySelector(selector)
                );

            this.el.button = this.el.container.querySelector('.js-meta-social-copy') ? this.el.container.querySelector('.js-meta-social-copy')
                : undefined;

            this.init();
        }

        init() {
            this.el.button.addEventListener('click', (event) => {
                const copyText = event.target.getAttribute('data-copyurl');
                navigator.clipboard.writeText(copyText);

                const copyTextElem = event.target.nextElementSibling;
                copyTextElem.style.opacity = 1;

                setTimeout(function() {
                    copyTextElem.style.opacity = 0;
                }, 720);
            });
        }
    };


    if (typeof window !== 'undefined') {
        window.a1LinkCopy = SesoCopy;
    }

    window.addEventListener('load', () => {
        document.querySelectorAll('a1-fragment[name="teaser-secondary-article"]').forEach(function(fragment) {
            window.a1LinkCopy = new SesoCopy(fragment.querySelector('.a1-teaser-secondary-article'));
        });
    });
}
